import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top"; // import assetsManageA from "./components/assets-manage-a";

import assetsManageB from "./components/assets-manage-b";
import assetsManageC from "./components/assets-manage-c";
import assetsManageD from "./components/assets-manage-d";
import assetsManageE from "./components/assets-manage-e";
import { mapMutations } from "vuex";
import CommonList3 from "@/components/common-list3";
export default {
  metaInfo: {
    title: "RFID资产管理案例",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID固定资产管理系统,RFID固定资产管理方案,物联网资产管方案,RFID电子标签资产管理方案,常达"
    }, {
      name: "description",
      content: "企业的固定资产种类繁多， 很多时候因资源太锁碎多变往往忽略其成本控制和财务统计的重要性。 常达智能可通过超高频RFID设备， 结合前端人工数据收集和后台计算大数据库， 促使企业轻松记录， 实时监察资源和记录， 充分体现“ 帐、 卡、 物” 数据相符的资产管理系统。"
    }]
  },
  components: {
    CommonList3: CommonList3,
    top: top,
    // assetsManageA,
    assetsManageB: assetsManageB,
    assetsManageC: assetsManageC,
    assetsManageD: assetsManageD,
    assetsManageE: assetsManageE
  },
  data: function data() {
    return {
      recommend: {
        title: "资产管理产品推荐",
        list: [{
          image: require("@/assets/assets-manage/recommend/1.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=2"
        }, {
          image: require("@/assets/assets-manage/recommend/2.png"),
          to: "/product?name=超高频普通标签"
        }, {
          image: require("@/assets/assets-manage/recommend/3.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/assets-manage/recommend/4.png"),
          to: "/product?name=四通道读写器&current=4"
        }, {
          image: require("@/assets/assets-manage/recommend/5.png"),
          to: "/product?name=桌面式读写器"
        }]
      },
      list: [{
        img: require("@/assets/assets-manage/e/1.png"),
        t: "制造业",
        c: ["在制造业工厂，设备多、人员多、空间大、范围广，通过资产设备上的RFID，可以提供其位置、资产状态等信息，这些信息有助于提高资产利用率，减少资产闲置，避免重复采购。"]
      }, {
        img: require("@/assets/assets-manage/e/2.png"),
        t: "制造业",
        c: ["将RFID系统与企业现有的制造执行系统、制造信息管理系统通过“中间件”连接，厂商能够全/半自动数据采集，实时获得产品生产各环节信息，管理原材料、半成品、成品的流转，为企业制定合理的生产计划提供科学依据，大大提高生产力，节约生产成本。"]
      }, {
        img: require("@/assets/assets-manage/e/3.png"),
        t: "基建类",
        c: ["基础设施布建，使用RFID技术作为基建管理和数据采集方式。通过RFID电子标签和RFID阅读器的搭配，在门禁管理、资产盘点、资产进出等方面，自动化采集信息并上传数据到后台，减少人工操作，提高管理效率。"]
      }, {
        img: require("@/assets/assets-manage/e/4.png"),
        t: "重工",
        c: ["在重工行业，设备资产、工具资产等多种不同类型的资产，使用RFID管理进出、位置、维护、折旧、报废等，精细化管理各种资产的状态。"]
      }]
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};